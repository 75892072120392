// -------------------------------------------
// Google Tag Manager
// -------------------------------------------

var dataLayer = [];

if (window.googleTagManagerContainerId) {
    var googleTagManagerContainerId = window.googleTagManagerContainerId;
    var googleTagManagerRole = window.role;
    var googleTagManagerUserId = window.personId;
    var googleTagManagerGroups = window.groups;

    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', googleTagManagerContainerId);

    googleTagManagerRole && googleTagManagerUserId && googleTagManagerGroups && (dataLayer.push({
        role: googleTagManagerRole,
        personId: googleTagManagerUserId,
        groups: googleTagManagerGroups
    }),
        dataLayer.push({
            event: "roleset"
        }));
}